import React from 'react'
import { Link, navigate } from 'gatsby'
import { useQueryParam, StringParam } from "use-query-params";
import { gql, useQuery } from '@apollo/client'
import { isLoggedIn } from '../services/auth'
import Seo from '../components/seo'

const SearchResult = () => {
  // 検索対象文字列の取得
  const [text] = useQueryParam("text", StringParam);

  const queryParams = {
    searchText: text,
  };

  const { loading, error, data } = useQuery(SEARCH_NOTICE, {
    variables: queryParams,
  });

  if (loading) {
    return (
      <div>Loading...</div>
    )
  }

  if (error) {
    navigate("/404")
    return null
  }

  // データ整形
  const { associationNotices: { edges: associationEdges }, notices: { edges: noticeEdges } } = data

  const filteredDataAll = associationEdges.map(v => v.node)
    .concat(noticeEdges.map(v => v.node)
      .filter(v => isLoggedIn() ? true : !v.MemberOnly.memberonly))
    .filter(v => v.title.includes(text) || v.content.includes(text))

  if (filteredDataAll.length === 0) {
    return (
      <>
        <div id="pageTitle">
          <h1>
            検索結果
          </h1>
        </div>
        <div className="breadClumb">
          <ul>
            <li><Link to="/">HOME</Link></li>
            <li>検索結果</li>
          </ul>
        </div>

        <div id="main">
          <div className="contents">
            <section className="contents_block">
              <h2 className="header01">検索結果</h2>
              <div className="inner">
                <p className="mb30">{text}の検索結果</p>
                <ul className="search_list">
                  検索結果に該当しませんでした
                </ul>
              </div>
            </section>
          </div>
        </div>
      </>
    )
  }

  const outputElements = filteredDataAll.map(v => {
    return (
      <li key={v.id}>
        <div className="search_head">
          <Link to={v.uri}>{v.title}</Link>
        </div>
      </li>
    )
  })

  return (
    <>
      <Seo title="検索結果" />
      <div id="pageTitle">
        <h1>
          検索結果
        </h1>
      </div>
      <div className="breadClumb">
        <ul>
          <li><Link to="/">HOME</Link></li>
          <li>検索結果</li>
        </ul>
      </div>

      <div id="main">
        <div className="contents">
          <section className="contents_block">
            <h2 className="header01">検索結果</h2>
            <div className="inner">
              <p className="mb30">{text}の検索結果</p>
              <ul className="search_list">
                {outputElements}
              </ul>
            </div>
          </section>
        </div>
      </div>
    </>
  )
}

const SEARCH_NOTICE = gql`
  query {
    notices {
      edges {
        node {
          id
          title
          content
          uri
          MemberOnly {
            memberonly
          }
        }
      }
    }
    associationNotices {
      edges {
        node {
          id
          title
          content
          uri
        }
      }
    }
  }
`

export default SearchResult